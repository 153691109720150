import React, { useState } from 'react';
import useAuth from './useAuthToken';

const UnlimitedButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [token] = useAuth();

  const handleEndTrial = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/easyexams/end_trial`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (response.ok) {
        // Trial ended successfully
        alert('Tu prueba ha terminado ahora puedes usar FotoExamen de forma ilimitada. Comprueba tu perfil para revisar tu subscripción. ¡Que lo disfrutes!');
        window.location.href = '/perfil';
      } else if (response.status === 400) {
        // Card validation failed
        setErrorMessage(data.message || 'La validación de la tarjeta falló. Por favor, verifica tu información de pago.');
      } else if (response.status === 404) {
        // Customer or trial subscription not found
        setErrorMessage(data.error || 'No se encontró una suscripción de prueba activa.');
      } else {
        // Other errors
        setErrorMessage(data.error || 'Ocurrió un error al procesar tu solicitud.');
      }
    } catch (error) {
      console.error('Error ending trial:', error);
      setErrorMessage('Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <button
        className="btn btn-primary w-full"
        onClick={handleEndTrial}
        disabled={isLoading}
      >
        {isLoading ? 'Procesando...' : 'FINALIZAR TRIAL'}
      </button>
      {errorMessage && <p className="text-red-500 mt-2 text-center">{errorMessage}</p>}
    </div>
  );
};

export default UnlimitedButton;
