import * as pdfjsLib from "pdfjs-dist/webpack";
import { default as React, useState } from "react";
import CurrentPath from "./CurrentPath";
import Quiz from "./Quiz";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import useMobile from "./useMobile";
const apiUrl = process.env.REACT_APP_API_URL;

const QuizFile = ({setShowNoTokenModal}) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [quiz, setQuiz] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, clearToken] = useAuth();

  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const mobile = useMobile();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setText(""); // Limpiar el texto para ocultar el área de texto
    setQuiz("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null); // Limpiar el archivo para ocultar el input de archivo
    setQuiz("");
  };

  const handleGenerateQuiz = async () => {
    if (!file && !text.trim()) {
      alert(
        "Por favor, sube un archivo o escribe algún texto antes de generar un quiz."
      );
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      return;
    }

    if(subscriptionType === "No Subscription"){
      setShowNoTokenModal(true);
      /* window.location.href="/ver-precios" */
      return;
    }

    if (subscriptionType.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    if (!subscriptionType.includes("Standard") && !subscriptionType.includes("Pro")) {
      alert(
        "Necesitas una suscripción de Pro o Standard para acceder a los cuestionarios."
      );
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    let pdftext = "";
    if (file) {
      if (file.type === "application/pdf") {
        try {
          const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file))
            .promise;
          let textContent = "";
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const text = await page.getTextContent();
            text.items.forEach((item) => {
              textContent += item.str + " ";
            });
          }
          pdftext = textContent;
          formData.append("text", pdftext);
        } catch (error) {
          console.error("Error extracting text from PDF:", error);
          setErrorMessage(
            "Error al extraer el texto del PDF. Por favor, prueba con otro archivo."
          );
          setIsLoading(false);
          return;
        }
      } else if (file.type.startsWith("image/")) {
        formData.append("files", file);
      } else {
        alert("Tipo de archivo no admitido. Sube una imagen o un archivo PDF.");
        setIsLoading(false);
        return;
      }
    } else {
      formData.append("text", text);
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/quiz`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();

      if (data?.too_long) {
        setErrorMessage(
          "El texto es demasiado extenso. Por favor, sube otro archivo más pequeño o introduce menos texto. Número de tokens excedido has subido: " +
            data?.total_tokens +
            " y el máximo son " +
            data?.max_tokens +
            " tokens."
        );
        return;
      }

      if (!response.ok) {
        throw new Error(data.message || "Error fetching the quiz.");
      }

      setQuiz(JSON.parse(data.quiz));
      refreshSubscription();
    } catch (error) {
      console.error("Error generating quiz:", error);
      setErrorMessage(
        "Error, pruebe nuevamente o con otro archivo más pequeño. Si el problema persiste contacte con nosotros."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Generador de Cuestionarios Inteligente</h2>
        <p className="mb-4 text-gray-600">
          Revoluciona tu método de estudio con nuestro generador de tests automatizado. Crea exámenes personalizados a partir de tus propios materiales de estudio, ya sean documentos PDF, imágenes o texto. Evalúa tu comprensión del temario de manera eficiente y efectiva.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Creación de preguntas automáticas basadas en tu contenido</li>
          <li className="mb-2">Tests adaptados a tu material de estudio específico</li>
          <li className="mb-2">Evaluación personalizada de conocimientos</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Versatilidad en la Entrada de Datos</h2>
        <p className="mb-4 text-gray-600">
          Nuestro sistema de generación de cuestionarios acepta múltiples formatos de entrada, adaptándose a tus necesidades y preferencias de estudio:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Carga de documentos PDF con tus apuntes o libros de texto</li>
          <li className="mb-2">Subida de imágenes de notas manuscritas o diagramas</li>
          <li className="mb-2">Introducción directa de texto para crear preguntas al instante</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Beneficios del Autoevaluación Automatizada</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Identificación rápida de áreas que requieren más estudio</li>
          <li className="mb-2">Refuerzo de conceptos clave a través de preguntas dirigidas</li>
          <li className="mb-2">Simulación de condiciones de examen para mejor preparación</li>
          <li className="mb-2">Ahorro de tiempo en la creación manual de cuestionarios</li>
          <li className="mb-2">Adaptación del nivel de dificultad según tu progreso</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Características Avanzadas de Nuestro Generador de Tests</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Análisis de contenido mediante inteligencia artificial</li>
          <li className="mb-2">Generación de preguntas de opción múltiple, verdadero/falso y respuesta corta</li>
          <li className="mb-2">Personalización de la cantidad y tipo de preguntas</li>
          <li className="mb-2">Retroalimentación instantánea sobre respuestas correctas e incorrectas</li>
          <li className="mb-2">Seguimiento del progreso y áreas de mejora</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Aplicaciones Prácticas</h2>
        <p className="mb-4 text-gray-600">
          Nuestro generador de cuestionarios es ideal para diversas situaciones de aprendizaje:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Preparación para exámenes académicos</li>
          <li className="mb-2">Repaso de material de estudio</li>
          <li className="mb-2">Evaluación continua en cursos online</li>
          <li className="mb-2">Autoevaluación para profesionales en formación continua</li>
          <li className="mb-2">Creación de actividades interactivas para educadores</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Potencia tu Aprendizaje con Tests Personalizados</h2>
        <p className="text-gray-600">
          Nuestro Generador de Cuestionarios Inteligente transforma la manera en que te preparas para exámenes y evalúas tu conocimiento. Al crear tests basados en tu propio material de estudio, garantizas una preparación enfocada y eficiente. Ya sea que estés estudiando para un examen importante, repasando para una certificación profesional, o simplemente reforzando tu comprensión de un tema, nuestra herramienta de generación automática de preguntas te ayudará a alcanzar tus objetivos de aprendizaje. ¡Comienza a crear tus cuestionarios personalizados hoy y lleva tu estudio al siguiente nivel!
        </p>
      </section>
    </div>
  );

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <CurrentPath text={"Generador de Quiz"} />
      <div className="container max-w-4xl mx-auto p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          📄 Generador de Quiz
        </h1>
        <p className="mb-6">
          Sube un documento en PDF o varias imágenes o puedes introducir texto
          directamente para generar un quiz basado en los apuntes
          proporcionados.
        </p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          Escribe un texto o sube un PDF
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder="Escribe aquí tu texto..."
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="input input-bordered w-full mt-4"
            />
          )}
        </div>

        <button onClick={handleGenerateQuiz} className="btn btn-primary mt-4">
          Generar Quiz
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">Procesando...</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {quiz && (
          <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line mt-8">
            <Quiz questions={quiz} />
          </div>
        )}
      </div>
      {!token && !mobile && <SEOText />}
    </div>
  );
};

export default QuizFile;
