import {
  faBars,
  faChalkboardTeacher,
  faCheckCircle,
  faClose,
  faEdit,
  faFileAlt,
  faHighlighter,
  faHistory,
  faMoneyBill,
  faQuestionCircle,
  faSitemap,
  faStickyNote,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ModalTrialEnded from "./ModalTrialEnded";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";

const Header = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const drawerCheckboxRef = useRef(null);
  const [token, clearToken] = useAuth();
  const {
    subscriptionType,
    remainingCredits,
    loading,
    error,
    refreshSubscription,
  } = useSubscription();

  useEffect(() => {
    // Al cargar el componente, obtener el estado del menú del localStorage
    const savedMenuState = localStorage.getItem("isMenuOpen");
    if (savedMenuState !== null) {
      setIsMenuOpen(JSON.parse(savedMenuState));
    } else setIsMenuOpen(true);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => {
      const newState = !prevState;
      localStorage.setItem("isMenuOpen", JSON.stringify(newState));
      return newState;
    });
  };

  const closeDrawer = () => {
    if (window.innerWidth < 768) {
      // Si la pantalla es menor que 768px (md)
      setIsMenuOpen(false);
      localStorage.setItem("isMenuOpen", JSON.stringify(false));
      if (drawerCheckboxRef.current) {
        drawerCheckboxRef.current.checked = false;
      }
    }
  };

  return (
    <div className={`drawer ${isMenuOpen ? "md:drawer-open" : ""}`}>
      <input
        id="my-drawer-2"
        type="checkbox"
        className="drawer-toggle"
        ref={drawerCheckboxRef}
      />
      <div className="drawer-content flex flex-col">
        <div className="navbar bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-md">
          <div className="navbar-start">
            <button
              onClick={toggleMenu}
              className="btn btn-ghost hidden md:flex"
            >
              {!isMenuOpen ? (
                <FontAwesomeIcon icon={faBars} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faClose} size="lg" />
              )}
            </button>

            <label
              htmlFor="my-drawer-2"
              className="btn btn-ghost drawer-button flex md:hidden"
            >
              <FontAwesomeIcon icon={faBars} size="lg" />
            </label>
            {/* <Link to="/" className="btn btn-ghost text-xl ml-2">
              FotoExamen
            </Link> */}
          </div>
          <div className="navbar-end">
            {token ? (
              <>
                {subscriptionType === "No Subscription" ? (
                  <Link to="/ver-precios" className="btn btn-primary">
                    ¡Suscríbete ahora!
                  </Link>
                ) : subscriptionType?.includes("Trial") ? (
                  remainingCredits === 0 ? (
                    <button
                      onClick={() =>
                        document.getElementById("trial_ended_modal").showModal()
                      }
                      className="btn btn-primary"
                    >
                      Te quedan 0 créditos
                    </button>
                  ) : (
                    <span className="text-white">
                      Te quedan {remainingCredits} crédito/s
                    </span>
                  )
                ) : null}
                <Link to="/perfil" className="btn btn-ghost">
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    size="lg"
                    className="mr-2"
                  />
                  Mi perfil
                </Link>
              </>
            ) : (
              <Link to="/iniciar-sesion" className="btn btn-ghost">
                <FontAwesomeIcon
                  icon={faUserCircle}
                  size="lg"
                  className="mr-2"
                />
                Iniciar sesión
              </Link>
            )}
          </div>
        </div>
        <div>{children}</div>
      </div>
      <div className="drawer-side z-40 border-r-2 border-gray-200">
        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
        <ul className="menu bg-base-100 text-base-content min-h-full w-80 p-4 flex justify-between">
          <div>
            <li className="font-bold text-md mt-4 flex justify-between items-center">
              <Link to="/" onClick={closeDrawer}>
                Aprende con FotoExamen 💙
              </Link>
            </li>
            <div className="divider"></div>
            <li>
              <Link to="/" onClick={closeDrawer}>
                <FontAwesomeIcon icon={faEdit} className="mr-2" width={20} />
                Resolver Examen
              </Link>
            </li>
            <li>
              <Link to="/crear-esquemas-con-ia" onClick={closeDrawer}>
                <FontAwesomeIcon icon={faSitemap} className="mr-2" width={20} />
                Crear Esquemas
              </Link>
            </li>
            <li>
              <Link to="/tutores-ia" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  className="mr-2"
                  width={20}
                />
                Tutores
              </Link>
            </li>
            <li>
              <Link to="/cuestionario-interactivo-ia" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mr-2"
                  width={20}
                />
                Crear Quiz
              </Link>
            </li>
            <li>
              <Link to="/crear-resumen-con-ia" onClick={closeDrawer}>
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" width={20} />
                Resumir Texto
              </Link>
            </li>
            <li>
              <Link to="/subrayar-texto-con-ia" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faHighlighter}
                  className="mr-2"
                  width={20}
                />
                Subrayar Texto
              </Link>
            </li>
            <li>
              <Link to="/corregir-examen-con-ia" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-2"
                  width={20}
                />
                Corregir Examen
              </Link>
            </li>
            <li>
              <Link to="/tomar-apuntes-con-ia" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faStickyNote}
                  className="mr-2"
                  width={20}
                />
                Tomar Apuntes
              </Link>
            </li>
            <Link
              to="https://play.google.com/store/apps/details?id=com.fotoexamen.android"
              className="alert alert-warning mb-6 mt-6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 shrink-0 stroke-current"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div>
                  <h3 className="font-bold">
                    ¡Descarga la APP disponible en Play Store!
                  </h3>
                  <div className="text-xs">
                    Si ya eres usuario, puedes usarla sin problemas con tu
                    cuenta actual.
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <div className="divider"></div>

            <li>
              <Link to="/historial" onClick={closeDrawer}>
                <FontAwesomeIcon icon={faHistory} className="mr-2" width={20} />
                Ver Historial
              </Link>
            </li>
            <li>
              <Link
                to="https://fotoexamen.com/faq.html"
                target="_blank"
                onClick={closeDrawer}
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mr-2"
                  width={20}
                />
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/ver-precios">
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  className="mr-2"
                  width={20}
                />
                Ver Precios
              </Link>
            </li>
            <li>
              {token ? (
                <Link to="/perfil" onClick={closeDrawer}>
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    className="mr-2"
                    width={20}
                  />
                  Mi Perfil
                </Link>
              ) : (
                <Link to="/iniciar-sesion" onClick={closeDrawer}>
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    size="lg"
                    className="mr-2"
                  />
                  Iniciar sesión
                </Link>
              )}
            </li>
          </div>
        </ul>
      </div>
      <ModalTrialEnded />
    </div>
  );
};

export default Header;
