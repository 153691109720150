import { select } from "d3-selection";
import { Transformer } from "markmap-lib";
import { Toolbar } from "markmap-toolbar";
import "markmap-toolbar/dist/style.css";
import { Markmap } from "markmap-view";
import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import CurrentPath from "./CurrentPath";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import useMobile from "./useMobile";

const apiUrl = process.env.REACT_APP_API_URL;

const MarkmapSchemaCreator = ({setShowNoTokenModal}) => {
  const [files, setFiles] = useState([]);
  const [markmapData, setMarkmapData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const transformer = new Transformer();
  const messageCount = useRef(0);
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();
  const mobile = useMobile();

  const markmapRef = useRef(null);
  const svgRef = useRef(null);
  const toolbarRef = useRef(null);
  const socketRef = useRef(null);


  const connectSocket = (onHtmlResponse, onGenerationComplete) => {
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Socket connected.");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });

    socket.on("markmapmsg", (data) => {
      onHtmlResponse(data);
    });

    socket.on("markmapend", (data) => {
      onGenerationComplete(data);
      socket.disconnect();
    });

    return socket;
  };

  useEffect(() => {
    if (markmapData && svgRef.current) {
      try {
        svgRef.current.innerHTML = "";
        const svg = select(svgRef.current)
          .attr("width", "100%")
          .attr("height", "100%")
          .attr(
            "viewBox",
            `0 0 ${svgRef.current.clientWidth} ${svgRef.current.clientHeight}`
          )
          .style("background-color", "white");

        const { root } = transformer.transform(
          markmapData.replace(/^```(markmap)?\n?|\n```$/gm, "").trim()
        );
        const mm = Markmap.create(svg.node(), { fit: true }, root);

        // Center the content
        mm.fit();

        if (toolbarRef.current) {
          toolbarRef.current.innerHTML = "";
          const toolbar = new Toolbar();
          toolbar.attach(mm);
          toolbar.setItems([...Toolbar.defaultItems]);
          toolbarRef.current.append(toolbar.render());
        }
      } catch (error) {
        console.error("Error processing Markmap data:", error);
        setErrorMessage(
          "Error procesando el mapa conceptual. Por favor, intenta de nuevo."
        );
      }
    }
  }, [markmapData]);

  const handleFilesChange = (e) => {
    setFiles([...e.target.files]);
  };

  const waitForSocketConnection = (socket) => {
    return new Promise((resolve, reject) => {
      const maxAttempts = 5;
      let attempts = 0;

      const checkSocketConnection = () => {
        if (socket.connected) {
          resolve();
        } else {
          attempts += 1;
          if (attempts >= maxAttempts) {
            reject(new Error("Socket connection failed"));
          } else {
            setTimeout(checkSocketConnection, 200); // Reintentar después de 200ms
          }
        }
      };

      checkSocketConnection();
    });
  };

  const handleSubmit = async () => {
    if (files.length === 0) {
      alert("Por favor, selecciona al menos un archivo.");
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoading(false);
      return;
    }

    if (subscriptionType === "No Subscription") {
      setShowNoTokenModal(true);
      /* window.location.href="/ver-precios" */
      return;
    }

    if (subscriptionType.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    if (!subscriptionType.includes("Standard") && !subscriptionType.includes("Pro")) {
      alert(
        "Necesitas una suscripción de Pro o Standard para acceder a los mapas conceptuales."
      );
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    socketRef.current = connectSocket(
      (data) => {
        messageCount.current += 1;
        if (messageCount.current % 100 === 0) {
          setMarkmapData(data?.markmapmsg);
        }
      },
      (data) => {
        setMarkmapData(data?.markmapend);
        setIsLoading(false);
      }
    );

    try {
      await waitForSocketConnection(socketRef.current);
      refreshSubscription();
    } catch (error) {
      alert("No se pudo establecer la conexión. Por favor, vuelve a intentarlo.");
      setIsLoading(false);
      return;
    }

    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve({ name: file.name, data: reader.result });
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      let textContent = "";

      if (files[0].type === "application/pdf") {
        // Aquí es donde se procesa el PDF
        const pdf = await pdfjsLib.getDocument(URL.createObjectURL(files[0]))
          .promise;

        // Verifica el número de páginas
        if (pdf.numPages > 45) {
          setIsLoading(false);
          setErrorMessage(
            "Error: el archivo es demasiado grande, máximo 45 páginas"
          );
          return;
        }

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          text.items.forEach((item) => {
            textContent += item.str + " ";
          });
        }
      }

      socketRef.current.emit("markmap", {
        token,
        files: fileDataArray,
        textContent,
      });
    } catch (error) {
      console.error("Error sending files:", error);
      setErrorMessage(
        "Error el archivo es demasiado grande, maximo 45 páginas"
      );
    }
  };

  const handleDownload = () => {
    const svgElement = svgRef.current;
    if (svgElement) {
      const serializer = new XMLSerializer();
      let source = serializer.serializeToString(svgElement);

      if (!source.match(/^<svg[^>]+xmlns="http:\/\/www.w3.org\/2000\/svg"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"'
        );
      }
      if (!source.match(/^<svg[^>]+"http:\/\/www.w3.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
        );
      }

      const svg64 = btoa(unescape(encodeURIComponent(source)));
      const image64 = "data:image/svg+xml;base64," + svg64;

      const element = document.createElement("a");
      element.href = image64;
      element.download = files[0].name.split(".")[0] + "-markmap.svg";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      console.error("SVG element not found for download.");
    }
  };


  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Generador de mapas mentales</h2>
        <p className="mb-4 text-gray-600">
          Nuestra plataforma de vanguardia transforma tus archivos en representaciones visuales dinámicas. Carga tus documentos, ya sean en formato digital o capturas de imagen, y observa cómo se convierten en diagramas estructurados y comprensibles. Esta herramienta es perfecta para desglosar información compleja, sistematizar conceptos y potenciar tu metodología de estudio.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Convierte archivos digitales y gráficos en diagramas dinámicos</li>
          <li className="mb-2">Representa visualmente ideas abstractas</li>
          <li className="mb-2">Sistematiza conceptos y optimiza tu proceso de asimilación</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Proceso de Creación de mapas conceptuales</h2>
        <ol className="list-decimal list-inside mb-4 text-gray-600">
          <li className="mb-2">Importa tus archivos (digitales o gráficos) - con un límite de 45 hojas por documento</li>
          <li className="mb-2">Nuestro sistema analiza y extrae los puntos clave</li>
          <li className="mb-2">Se genera automáticamente un diagrama estructurado y dinámico</li>
          <li className="mb-2">Adapta y modifica el diagrama según tus preferencias</li>
        </ol>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Ventajas de los esquemas conceptuales con IA</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Facilita la comprensión de temas intrincados</li>
          <li className="mb-2">Reduce el tiempo dedicado a la elaboración de síntesis</li>
          <li className="mb-2">Potencia la retención y revisión de ideas fundamentales</li>
          <li className="mb-2">Óptimo para la preparación de evaluaciones y exposiciones</li>
          <li className="mb-2">Estimula el aprendizaje visual y el pensamiento creativo</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Usos Prácticos</h2>
        <p className="mb-4 text-gray-600">
          Nuestro Generador de Diagramas Cognitivos se adapta a diversas necesidades académicas y profesionales:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Alumnos: Optimiza tus notas y mejora tu técnica de estudio</li>
          <li className="mb-2">Docentes: Diseña recursos pedagógicos visualmente atractivos</li>
          <li className="mb-2">Académicos: Organiza y sintetiza datos de diversas fuentes</li>
          <li className="mb-2">Expertos: Elabora presentaciones claras y bien estructuradas</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Funcionalidades Destacadas</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Soporte para archivos digitales y diversos formatos de imagen</li>
          <li className="mb-2">Capacidad de procesar documentos de hasta 45 hojas</li>
          <li className="mb-2">Interfaz intuitiva de fácil manejo</li>
          <li className="mb-2">Diagramas adaptables y modificables</li>
          <li className="mb-2">Opción de exportar en varios formatos para una fácil distribución</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Revoluciona tu Método de Estudio</h2>
        <p className="text-gray-600">
          Nuestro Generador de Diagramas Cognitivos no es solo una herramienta, es un aliado en tu desarrollo académico. Redefine la manera en que procesas y asimilas información, haciendo que tu estudio sea más eficaz y productivo. Ya sea que estés preparándote para una evaluación, organizando un proyecto o buscando comprender mejor un tema complejo, nuestros diagramas interactivos te ayudarán a alcanzar tus metas educativas. ¡Comienza a crear representaciones visuales inteligentes hoy y eleva tu aprendizaje a un nuevo nivel!
        </p>
      </section>
    </div>
  );
  return (
    <>
      <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
        <CurrentPath text={"Creador de Esquemas"} />
        <div className="container max-w-4xl mx-auto p-8 rounded">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
            📚 Creador de Esquemas Interactivos
          </h1>

          <div className="text-center mb-6 p-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700">
            <p className="font-semibold">
              Transforma tus documentos en mapas mentales interactivos
            </p>
            <p>
              Sube los archivos de los cuales quieras obtener un esquema, ya sea
              en formato PDF o varias imágenes, y conviértelos automáticamente
              en esquemas claros y estructurados. Ideal para visualizar
              conceptos complejos, organizar ideas y mejorar tu proceso de
              aprendizaje. <strong>Máximo pdfs con 45 páginas</strong>
            </p>
          </div>

          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <input
              type="file"
              multiple
              onChange={handleFilesChange}
              className="w-full px-4 py-2 border rounded-lg text-sm leading-normal transition duration-150 ease-in-out sm:text-base"
            />
            <button
              onClick={handleSubmit}
              className="w-full sm:w-auto btn btn-primary font-semibold py-2 px-4 rounded transition ease-in-out duration-300 flex flex-row items-center justify-center"
            >
              <span className="mr-2">Generar Mapa Conceptual</span>
              {isLoading && (
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
              )}
            </button>
          </div>

          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </div>
      </div>
      <div className="w-full p-8 shadow-lg">
        {markmapData && (
          <>
            <h2 className="text-2xl font-bold border-b-4 border-white text-center">
              {" "}
              Mapa Conceptual
            </h2>
            <button
              onClick={handleDownload}
              className="mt-4 text-center mb-6 p-4 bg-blue-100 border-blue-500 text-blue-700 font-bold py-2 px-4 rounded"
            >
              Descargar Mapa
            </button>
            <div
              ref={markmapRef}
              style={{
                width: "100%",
                height: "80vh",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <svg ref={svgRef}></svg>
              <div className="absolute top-1 right-1" ref={toolbarRef}></div>
            </div>
          </>
        )}
        {!token && !mobile && <SEOText />}
      </div>
    </>
  );
};

export default MarkmapSchemaCreator;
