import React from 'react'

export default function FAQ() {
  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">Preguntas frecuentes</h2>
        <div className="space-y-4">
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              ¿Cómo puedo cancelar mi suscripción?
            </div>
            <div className="collapse-content"> 
              <p>Puede cancelar su suscripción en cualquier momento desde la configuración de su cuenta. La cancelación será efectiva al final del período de facturación actual.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              ¿Cuáles son los métodos de pago aceptados?
            </div>
            <div className="collapse-content"> 
              <p>Aceptamos tarjetas de crédito y débito de las principales marcas, así como PayPal.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              ¿Ofrecen reembolsos?
            </div>
            <div className="collapse-content"> 
              <p>Sí, ofrecemos un reembolso completo si cancela dentro de los primeros 15 días de su suscripción.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              ¿Qué incluye mi suscripción?
            </div>
            <div className="collapse-content"> 
              <p>Su suscripción incluye acceso completo a todas nuestras funciones premium, actualizaciones regulares y soporte al cliente prioritario.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              ¿Con qué frecuencia se factura?
            </div>
            <div className="collapse-content"> 
              <p>Ofrecemos opciones de facturación mensual y anual. Puede elegir la que mejor se adapte a sus necesidades.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              ¿Hay un período de prueba gratuito?
            </div>
            <div className="collapse-content"> 
              <p>Si ofrecemos un período de prueba gratuito de 3 días y además puede solicitar un reembolso en los 15 primeros días si no está satisfecho con nuestro servicio.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              ¿Hay alguna ventaja en elegir la suscripción anual?
            </div>
            <div className="collapse-content"> 
              <p>Sí, al suscribirse al plan anual, el precio es más de un 50% más barato, lo que equivale a más de 6 meses gratis en comparación con el precio mensual regular. Asegúrese de seleccionar el plan anual para ahorrar una cantidad significativa de dinero. Esta es nuestra forma de agradecerle su confianza en nuestro servicio y nos permite reinvertir su dinero para mejorar el producto.</p>
            </div>
          </div>

          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              ¿Cómo puedo gestionar mi suscripción?
            </div>
            <div className="collapse-content"> 
              <p>Puede gestionar su suscripción en cualquier momento. Vaya a la parte superior de la página cuando haya iniciado sesión y haga clic en "Facturación". Será redirigido al portal de facturación de Stripe donde podrá cambiar de plan, descargar facturas y cancelar su suscripción si lo desea.</p>
            </div>
          </div>

          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              ¿Es seguro el proceso de pago?
            </div>
            <div className="collapse-content"> 
              <p>Sí, nuestro servicio de pago es seguro. Utilizamos Stripe para el procesamiento financiero y no almacenamos ninguna información sobre su tarjeta. Stripe garantiza estándares de seguridad a nivel bancario.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
