import React from 'react';
import { useSubscription } from './SubscriptionContext';
import UnlimitedButton from './UnlimitedButton';

const ModalTrialEnded = () => {
  const { subscriptionType } = useSubscription();

  return (
    <dialog id="trial_ended_modal" className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Créditos del Trial Agotados</h3>
        <div className="py-4">
          <p className="mb-2">¡Vaya! Parece que has agotado tus créditos de prueba.</p>
          <p className="mb-2">Para seguir disfrutando de FotoExamen sin límites con el plan <span className="font-bold text-blue-600">{subscriptionType?.replaceAll("Trial", "")}</span>, es hora de dar el siguiente paso.</p>
          <p className="">Continúa aprovechando todas las ventajas de FotoExamen.</p>
        </div>
        <div className="modal-action w-full">
          <UnlimitedButton />
        </div>
      </div>
    </dialog>
  );
};

export default ModalTrialEnded;