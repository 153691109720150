import React from 'react'
import { Link } from 'react-router-dom'

export default function ModalNoToken({setShowNoTokenModal}) {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg border">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold">¡Ups! Acceso requerido</p>
          <div
            className="cursor-pointer z-50 btn btn-ghost"
            onClick={() => setShowNoTokenModal(false)}
          >
            <svg
              className="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M12.3 11.7l-3.3-3.3 3.3-3.3L11.7 5l-3.3 3.3L5 5 4.3 5.7l3.3 3.3-3.3 3.3L5 12.3l3.3-3.3 3.3 3.3z" />
            </svg>
          </div>
        </div>
        <p className="mb-4">
          Para utilizar esta herramienta, necesitas iniciar sesión y tener una suscripción activa.
        </p>
        <p className="mb-4">
          ¿Aún no tienes una cuenta? ¡Regístrate ahora y disfruta de 3 días de prueba gratis!
        </p>
        <div className="flex flex-col space-y-2 pt-2">
        <button
            className="w-full btn btn-primary"
            onClick={() => window.location.href = "/ver-precios"}
          >
            Registrarse y probar gratis
          </button>
          <Link
            className="w-full btn btn-outline btn-primary"
            to="/iniciar-sesion"
            onClick={() => setShowNoTokenModal(false)}
          >
            Iniciar sesión
          </Link>
        </div>
      </div>
    </div>
  )
}
