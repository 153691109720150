import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import { io } from "socket.io-client";
import CurrentPath from "./CurrentPath.js";
import { downloadPDF } from "./ExamList.js";
import MarkdownPreview from "./MarkdownPreview.js";
import { useSubscription } from "./SubscriptionContext.js";
import useAuth from "./useAuthToken.js";
import useMobile from "./useMobile.js";

const apiUrl = process.env.REACT_APP_API_URL;

const NoteTaker = ({setShowNoTokenModal}) => {
  const [audio, setAudio] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [notes, setNotes] = useState("");
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isGeneratingNotes, setIsGeneratingNotes] = useState(false);
  const [notesData, setNotesData] = useState(null);
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();
  const [isDownloading, setIsDownloading] = useState(false);
  const mobile = useMobile();

  const socketRef = useRef(null);

  useEffect(() => {
    setNotesData({
      transcription: transcription,
      notes: notes,
      lastTranscription: new Date(),
    });
  }, [transcription, notes]);

  const connectSocket = () => {
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Socket connected.");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });

    return socket;
  };

  const handleAudioChange = (e) => {
    if(!token){
      setShowNoTokenModal(true);
      return;
    }
    
    if (!["Pro"].includes(subscriptionType)) {
      alert("Necesitas una suscripción premium pro para usar Tomar Apuntes.");
      return;
    }

    if (subscriptionType.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    const file = e.target.files[0];
    if (file && file.type.startsWith("audio/")) {
      setAudio(file);
    } else {
      alert("Por favor, sube un archivo de audio válido.");
    }
  };

  const transcribeAudio = async () => {
    setIsTranscribing(true);
    if (!token) {
      setShowNoTokenModal(true);
      setIsTranscribing(false);
      return;
    }

    socketRef.current = connectSocket();

    refreshSubscription();

    socketRef.current.on("transcribe_response", (data) => {
      setTranscription((prev) => prev + "\n" + data.text); // Adding newline for better formatting
    });

    socketRef.current.on("transcribe_end", () => {
      setIsTranscribing(false);
      socketRef.current.disconnect();
    });

    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result.split(",")[1];
      socketRef.current.emit("transcribe", {
        token: token,
        files: { audio: base64Data },
      });
    };
    reader.readAsDataURL(audio);
  };

  const generateNotes = async () => {
    setIsGeneratingNotes(true);
    if (!token) {
      setIsGeneratingNotes(false);
      return;
    }

    socketRef.current = connectSocket();

    socketRef.current.on("notes_response", (data) => {
      setNotes((prev) => prev + data.notes);
    });

    socketRef.current.on("notes_end", () => {
      setIsGeneratingNotes(false);
      socketRef.current.disconnect();
    });

    socketRef.current.emit("generate_notes", {
      token: token,
      transcription: transcription,
    });
  };
  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Tomar Apuntes con IA</h2>
        <p className="mb-4 text-gray-600">
          Revoluciona tu manera de tomar apuntes con nuestra inteligencia artificial avanzada. Sube un archivo de audio de tu clase, deja que nuestra IA lo transcriba y genera apuntes concisos y claros automáticamente. Esta herramienta es perfecta para estudiantes y profesionales que necesitan maximizar su eficiencia y garantizar la precisión de sus notas.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Sube archivos de audio de tus clases</li>
          <li className="mb-2">Transcripción automática y precisa del audio</li>
          <li className="mb-2">Generación de apuntes concisos y bien estructurados</li>
          <li className="mb-2">Ideal para estudiantes y profesionales</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Cómo Funciona</h2>
        <p className="mb-4 text-gray-600">
          Nuestro sistema de toma de apuntes con IA es fácil de usar y altamente eficiente. Sube tu archivo de audio a nuestra plataforma y la IA se encargará de transcribir el contenido de manera precisa. Luego, utiliza nuestra herramienta para generar apuntes estructurados que te ayudarán a estudiar y revisar el material de manera efectiva.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Sube el archivo de audio de tu clase</li>
          <li className="mb-2">La IA transcribe el contenido automáticamente</li>
          <li className="mb-2">Genera apuntes bien organizados y claros</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Ventajas de Tomar Apuntes con IA</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Ahorro de tiempo en la toma de apuntes</li>
          <li className="mb-2">Precisión y claridad en los apuntes generados</li>
          <li className="mb-2">Facilidad para revisar y estudiar el material</li>
          <li className="mb-2">Reducción del esfuerzo manual en la transcripción</li>
          <li className="mb-2">Acceso a apuntes bien estructurados en cualquier momento</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Aplicaciones de la Toma de Apuntes con IA</h2>
        <p className="mb-4 text-gray-600">
          Nuestra herramienta de toma de apuntes con IA es versátil y útil en una variedad de contextos académicos y profesionales. Ya sea que estés en la universidad, en una conferencia o en una reunión importante, esta herramienta te permitirá obtener apuntes precisos y organizados sin esfuerzo.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Estudiantes en clases y conferencias</li>
          <li className="mb-2">Profesionales en reuniones y presentaciones</li>
          <li className="mb-2">Investigadores y académicos en seminarios</li>
          <li className="mb-2">Cualquiera que necesite apuntes precisos y bien estructurados</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Mejora tu Toma de Apuntes con IA</h2>
        <p className="text-gray-600">
          Nuestra herramienta de toma de apuntes con IA está diseñada para transformar la manera en que capturas y organizas información. Sube tu archivo de audio, permite que la IA lo transcriba y genere apuntes concisos y claros para ti. Ya sea que estés estudiando para un examen, asistiendo a una conferencia o participando en una reunión, esta herramienta te ayudará a obtener apuntes perfectos de manera rápida y eficiente. ¡Empieza a usar nuestra herramienta de toma de apuntes con IA hoy mismo y lleva tu estudio y trabajo al siguiente nivel!
        </p>
      </section>
    </div>
  );
  
  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <CurrentPath text="Tomar Apuntes" />
      <div className="container max-w-4xl mx-auto p-8 bg-white rounded">
        <div className="mb-8 text-center max-w-xl mx-auto">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
            📝 Tomar Apuntes
          </h1>
          <p>
            Sube un archivo de audio de tu clase, transcríbelo y genera apuntes
            concisos utilizando nuestra inteligencia artificial avanzada.
          </p>
        </div>

        <div className="mb-8 space-y-6">
          <h2 className="text-2xl font-bold mb-4">Sube tu archivo de audio:</h2>
          <div className="flex justify-center items-center">
            <input
              type="file"
              accept="audio/*"
              className="file-input w-full max-w-xs"
              onChange={handleAudioChange}
            />
          </div>
        </div>

        {audio && (
          <div className="mb-8">
            <audio controls className="w-full">
              <source src={URL.createObjectURL(audio)} type={audio.type} />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </div>
        )}

        <div className="mb-8">
          <button
            className={`btn btn-primary flex my-4 justify-center items-center bg-blue-200 hover:bg-blue-300 text-blue-700 font-semibold py-3 px-6 rounded transition ease-in-out duration-300 ${
              !audio || isTranscribing ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={transcribeAudio}
            disabled={!audio || isTranscribing}
          >
            <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
            Transcribir Audio
          </button>
        </div>

        {isTranscribing && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">Transcribiendo audio...</p>
          </div>
        )}

        {transcription && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Transcripción:</h2>
            <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
              {transcription}
            </div>
          </div>
        )}

        {transcription && (
          <div className="mb-8">
            <button
              className={`btn btn-primary flex my-4 justify-center items-center bg-green-200 hover:bg-green-300 text-green-700 font-semibold py-3 px-6 rounded transition ease-in-out duration-300 ${
                isGeneratingNotes || isTranscribing
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={generateNotes}
              disabled={isGeneratingNotes || isTranscribing}
            >
              Generar Apuntes
            </button>
          </div>
        )}

        {isGeneratingNotes && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">Generando apuntes...</p>
          </div>
        )}

        {notes && (
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Apuntes generados:</h2>
            {isDownloading ? (
                <div className="flex flex-col justify-center items-center mb-2 mt-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-red-600"></div>
                  <p className="text-sm font-semibold mt-2">Descargando PDF...</p>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setIsDownloading(true);
                    downloadPDF(notesData).finally(() => setIsDownloading(false));
                  }}
                  className="mb-2 bg-red-200 hover:bg-red-300 text-red-800 font-semibold py-2 px-4 rounded transition ease-in-out duration-300 flex items-center justify-center mt-4"
                >
                  <FaFilePdf size={24} className="mr-2" /> Descargar PDF 
                </button>
              )}
            <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
              <MarkdownPreview content={notes}/>
            </div>
          </div>
        )}
      </div>
      {!token && !mobile && <SEOText />}
    </div>
  );
};

export default NoteTaker;
