import React, { useEffect, useState } from "react";
import ReactGA4 from "react-ga4";
import { clarity } from "react-microsoft-clarity";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "tailwindcss/tailwind.css";

import { App as CapacitorApp } from "@capacitor/app";
import { Dialog } from "@capacitor/dialog";
import { Preferences } from "@capacitor/preferences";
import Androidapp from "./components/Androidapp";
import Bienvenido from "./components/Bienvenido";
import MarkmapSchemaCreator from "./components/ConceptualMaps";
import ExamCorrector from "./components/ExamCorrector";
import ExamList from "./components/ExamList";
import ExamSolver from "./components/ExamSolver";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Highlight from "./components/Highlight";
import Login from "./components/Login";
import ModalNoToken from "./components/ModalNoToken";
import NoteTaker from "./components/NoteTaker";
import QuizFile from "./components/QuizFile";
import { SubscriptionProvider, useSubscription } from "./components/SubscriptionContext";
import Summary from "./components/Summary";
import { TutorsGallery } from "./components/TutorsGallery";
import useAuth from "./components/useAuthToken";
import UserProfile from "./components/UserProfile";
import VerPrecios from "./components/VerPrecios";

const TRACKING_ID = "G-6FQ7N7HHNB"; // Replace with your Google Analytics 4 tracking ID

const PageTracker = () => {
  const { subscriptionType } = useSubscription();
  const location = useLocation();

  CapacitorApp.addListener("appUrlOpen", (data) => {
    console.log("App opened with URL:", data?.url);
    const url = new URL(data?.url);
    const token = url.searchParams.get("token");
    if (token) {
      Preferences.set({
        key: "token",
        value: token,
      }).then(() => {
        console.log("Token saved");
        Dialog.alert({
          title: "Inicio sesión",
          message: "Has iniciado sesión correctamente",
        });
        window.location.href = "/";
      });
    }
  });

  useEffect(() => {
    clarity.init("o66nvqjnct");
    ReactGA4.initialize(TRACKING_ID);
    ReactGA4.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
    if (localStorage.getItem("email")) {
      window.plausible("pageview", {
        props: {
          path: location.pathname + location.search,
          email: localStorage.getItem("email"),
        },
      });
    } else
      window.plausible("pageview", {
        props: { path: location.pathname + location.search },
      });
  }, [location]);

  useEffect(() => {
    const showModalAfterTimeout = () => {
      const modalShown = localStorage.getItem("modalShown");
      const token = localStorage.getItem("token");
      if (!modalShown && token && subscriptionType !== "No Subscription") {
        setTimeout(() => {
          document.getElementById("my_modal_3").showModal();
          localStorage.setItem("modalShown", "true");
        }, 240000); // 4 minutos
      }
    };

    if (subscriptionType !== null) showModalAfterTimeout();
  }, [subscriptionType]);

  return null; // This component does not render anything
};

function HandleNoToken({ showNoTokenModal, setShowNoTokenModal }) {
  const [token, clearToken] = useAuth();
  useEffect(() => {
    if (token === null && showNoTokenModal) {
      setShowNoTokenModal(true);
    }
  }, [showNoTokenModal, setShowNoTokenModal, token]);
  return (
    showNoTokenModal && (
      <ModalNoToken setShowNoTokenModal={setShowNoTokenModal} />
    )
  );
}

function App() {
  const [showNoTokenModal, setShowNoTokenModal] = useState(false);

  return (
    <Router>
      <SubscriptionProvider>
        <HandleNoToken
          showNoTokenModal={showNoTokenModal}
          setShowNoTokenModal={setShowNoTokenModal}
        />
        <div className="App">
          <Header>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <PageTracker />
                    <ExamSolver setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/ver-precios"
                element={
                  <>
                    <PageTracker />
                    <VerPrecios />
                  </>
                }
              />
              <Route
                path="/bienvenida"
                element={
                  <>
                    <PageTracker />
                    <Bienvenido setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/corregir-examen-con-ia"
                element={
                  <>
                    <PageTracker />
                    <ExamCorrector setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/tomar-apuntes-con-ia"
                element={
                  <>
                    <PageTracker />
                    <NoteTaker setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/subrayar-texto-con-ia"
                element={
                  <>
                    <PageTracker />
                    <Highlight setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/crear-resumen-con-ia"
                element={
                  <>
                    <PageTracker />
                    <Summary setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/crear-esquemas-con-ia"
                element={
                  <>
                    <PageTracker />
                    <MarkmapSchemaCreator
                      setShowNoTokenModal={setShowNoTokenModal}
                    />
                  </>
                }
              />
              <Route
                path="/tutores-ia"
                element={
                  <>
                    <PageTracker />
                    <TutorsGallery setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/cuestionario-interactivo-ia"
                element={
                  <>
                    <PageTracker />
                    <QuizFile setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/quizz"
                element={<Navigate to="/cuestionario-interactivo-ia" replace />}
              />
              <Route
                path="/quiz"
                element={<Navigate to="/cuestionario-interactivo-ia" replace />}
              />
              <Route
                path="/apuntes"
                element={<Navigate to="/tomar-apuntes-con-ia" replace />}
              />
              <Route
                path="/esquemas"
                element={<Navigate to="/crear-esquemas-con-ia" replace />}
              />
              <Route
                path="/tutores"
                element={<Navigate to="/tutores-ia" replace />}
              />
              <Route
                path="/subrayar"
                element={<Navigate to="/subrayar-texto-con-ia" replace />}
              />
              <Route
                path="/resumen"
                element={<Navigate to="/crear-resumen-con-ia" replace />}
              />
              <Route
                path="/corregir"
                element={<Navigate to="/corregir-examen-con-ia" replace />}
              />
              <Route
                path="/historial"
                element={
                  <>
                    <PageTracker />
                    <ExamList
                      userEmail="user@example.com"
                      setShowNoTokenModal={setShowNoTokenModal}
                    />
                  </>
                }
              />
              <Route
                path="/perfil"
                element={
                  <>
                    <PageTracker />
                    <UserProfile setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/android-app"
                element={
                  <>
                    <PageTracker />
                    <Androidapp />
                  </>
                }
              />
              <Route
                path="/iniciar-sesion"
                element={
                  <>
                    <PageTracker />
                    <Login />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <PageTracker />
                    <h1 className="text-center mt-10 text-2xl font-bold">
                      Página no encontrada
                    </h1>
                  </>
                }
              />
            </Routes>
            <Footer />
          </Header>
        </div>
      </SubscriptionProvider>
    </Router>
  );
}

export default App;
