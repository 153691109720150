import React, { useEffect, useState } from "react";
import useAuth from "./useAuthToken";
const apiUrl = process.env.REACT_APP_API_URL;

export default function Precios() {
  const [activeTab, setActiveTab] = useState("monthly");
  const [email, setEmail] = useState("");
  const [token, clearToken] = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      if (token === "") return;

      const response = await fetch(apiUrl + "/api/easyexams/checktoken", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Usa el token en la petición
        },
      });

      // Aquí haces la llamada a la API para obtener los datos del usuario usando el token
      // Suponiendo que la API responde con los datos en el formato { email, dateSuscriptionFinish, tripeLink }
      //const data = await fakeApiCall(token); // Reemplaza esto con tu llamada a la API real
      const dataToken = await response.json();
      if (dataToken?.email) {
        localStorage.setItem("email", dataToken.email);
        setEmail(dataToken.email);
      }
    };
    fetchUserData();
  }, [token]);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }

    const updatePlanPrices = (
      planCard,
      monthlyPrice,
      annualPrice,
      monthlyUrl,
      annualUrl
    ) => {
      if (activeTab === "monthly") {
        monthlyPrice?.classList.remove("hidden");
        annualPrice?.classList.add("hidden");
        planCard.href = email
          ? `${monthlyUrl}?prefilled_email=${encodeURIComponent(email)}`
          : monthlyUrl;
      } else {
        monthlyPrice?.classList.add("hidden");
        annualPrice?.classList.remove("hidden");
        planCard.href = email
          ? `${annualUrl}?prefilled_email=${encodeURIComponent(email)}`
          : annualUrl;
      }
    };

    const planCard1 = document.getElementById("plan-card1");
    const monthlyPrice1 = document.getElementById("monthly-price1");
    const annualPrice1 = document.getElementById("annual-price1");

    const planCard2 = document.getElementById("plan-card2");
    const monthlyPrice2 = document.getElementById("monthly-price2");
    const annualPrice2 = document.getElementById("annual-price2");

    const planCard3 = document.getElementById("plan-card3");
    const monthlyPrice3 = document.getElementById("monthly-price3");
    const annualPrice3 = document.getElementById("annual-price3");

    // Update prices for Plan 1
    if (planCard1 && monthlyPrice1 && annualPrice1) {
      updatePlanPrices(
        planCard1,
        monthlyPrice1,
        annualPrice1,
        "https://buy.stripe.com/9AQ5lEaST3Le7tK3ce",
        "https://buy.stripe.com/dR68xQ5yzftW15maER"
      );
    }

    // Update prices for Plan 2
    if (planCard2 && monthlyPrice2 && annualPrice2) {
      updatePlanPrices(
        planCard2,
        monthlyPrice2,
        annualPrice2,
        "https://buy.stripe.com/7sIaFYf996Xq3du8wz",
        "https://buy.stripe.com/3cscO6aST0z201i9AO"
      );
    }

    // Update prices for Plan 3
    if (planCard3 && monthlyPrice3 && annualPrice3) {
      updatePlanPrices(
        planCard3,
        monthlyPrice3,
        annualPrice3,
        "https://buy.stripe.com/00g6pI3qr1D6aFW3cg",
        "https://buy.stripe.com/00geWebWXepS01i7sH"
      );
    }
  }, [activeTab, email]);

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center mt-8 mb-2">
          <button
            className={`mr-2 ${
              activeTab === "monthly"
                ? "links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700y"
                : "btn"
            }`}
            onClick={() => setActiveTab("monthly")}
          >
            Mensual
          </button>
          <button
            className={`${
              activeTab === "annual"
                ? "links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700"
                : "btn"
            }`}
            onClick={() => setActiveTab("annual")}
          >
            🔥 Anual: 6+ meses gratis
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-stretch">
        <a
          id="plan-card1"
          className="card bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between my-8 hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          href="https://buy.stripe.com/9AQ5lEaST3Le7tK3ce"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <div id="monthly-price1" className="text-5xl font-bold">
              7€ <span className="text-sm">/ mes</span>
            </div>
            <div id="annual-price1" className="text-5xl font-bold hidden">
              3,5€ <span className="text-sm">/ mes </span>
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">Plan Básico</h2>
            <ul className="my-4 text-left">
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Resolver y corregir 
                </span> {" "}
                exámenes, tareas o ejercicios sin límite
              </li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  IA de nivel básica
                </span> {" "}
              </li>
              <li>✔ Chatear con los Tutores IA</li>
              <li>✔ Entrena al Tutor IA para hablar con tus PDFs</li>
              <li>✔ Resumir textos</li>
              <li>✔ Subrayar documentos</li>
              <li>✔ Historial completo de examenes</li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700 text-center">
              Prueba gratis de 3 días ➤
            </div>
            <div className="text-xs mt-4">
              Disfruta de mejoras constantes y nuestra IA sin límites. Prueba gratis de 3 días.
            </div>
          </div>
        </a>
        <a
          id="plan-card2"
          className="card bg-white p-6 shadow-xl rounded-lg border-2 border-info flex flex-col justify-between my-8 hover:shadow-2xl transition-shadow duration-300 cursor-pointer"
          href="https://buy.stripe.com/7sIaFYf996Xq3du8wz"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <div className="badge badge-info mb-4 text-white">Más popular</div>
            <div id="monthly-price2" className="text-5xl font-bold">
              17€ <span className="text-sm">/ mes</span>
            </div>
            <div id="annual-price2" className="text-5xl font-bold hidden">
              8,5€ <span className="text-sm">/ mes </span>
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">Plan Standard</h2>
            <ul className="my-4 text-left">
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Resolver y corregir
                </span> {" "}
                exámenes, tareas o ejercicios sin límite
              </li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  IA nivel media
                </span> {" "}
                : más entrenada que la básica y con mejores resultados.
              </li>
              <li>
                ✔<span className="underline decoration-sky-500">Resumir</span> {" "}
                textos
              </li>
              <li>
                ✔<span className="underline decoration-sky-500">Subrayar</span> {" "}
                documentos
              </li>
              <li>✔ Historial completo de examenes</li>
              <li>
                ✔ Chatear con los {" "}
                <span className="underline decoration-sky-500">
                  Tutores IA Mejorados
                </span> {" "}
              </li>
              <li>
                ✔ Entrena al Tutor IA Mejorado para {" "}
                <span className="underline decoration-sky-500">
                  hablar con tus PDFs
                </span> {" "}
              </li>
              <li>✔ Más velocidad de procesamiento</li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Creador de esquemas
                </span> {" "}
                conceptuales con IA
              </li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Quizzes interactivos
                </span> {" "}
              </li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700 text-center">
              Prueba gratis de 3 días ➤
            </div>
            <div className="text-xs mt-4">
              Disfruta de mejoras constantes y nuestra IA sin límites. Prueba gratis de 3 días.
            </div>
          </div>
        </a>

        <a
          id="plan-card3"
          className="card bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between my-8 hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          href="https://buy.stripe.com/00g6pI3qr1D6aFW3cg"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <div id="monthly-price3" className="text-5xl font-bold">
              30€ <span className="text-sm">/ mes</span>
            </div>
            <div id="annual-price3" className="text-5xl font-bold hidden">
              15€ <span className="text-sm">/ mes</span>
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">Plan Pro</h2>
            <ul className="my-4 text-left">
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Resolver y corregir
                </span> {" "}
                exámenes, tareas o ejercicios sin límite
              </li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  IA nivel avanzada
                </span> {" "}
                : La IA más avanzada del mercado y con mejores resultados que la
                media y básica
              </li>
              <li>✔ Resumir textos</li>
              <li>✔ Subrayar documentos</li>
              <li>✔ Historial completo de examenes</li>
              <li>✔ Chatear con los Tutores IA Mejorados</li>
              <li>✔ Entrena al Tutor IA Mejorado para hablar con tus PDFs</li>
              <li>✔ Más velocidad de procesamiento</li>
              <li>✔ Creador de esquemas conceptuales con IA</li>
              <li>✔ Quizzes interactivos</li>
              <li>✔ Reconocimiento mejorado de gráficas, fórmulas y dibujos</li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700 text-center">
              Prueba gratis de 3 días ➤
            </div>
            <div className="text-xs mt-4">
              Disfruta de mejoras constantes y nuestra IA sin límites. Prueba gratis de 3 días.
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
