import { Directory, Filesystem } from "@capacitor/filesystem";
import { Preferences } from "@capacitor/preferences";
import { FileOpener } from "@capawesome-team/capacitor-file-opener";
import {
    Document,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
    pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import html2canvasPro from "html2canvas-pro";
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFilePdf } from "react-icons/fa";
import CurrentPath from "./CurrentPath";
import "./ExamList.css";
import MarkdownPreview from "./MarkdownPreview";

const apiUrl = process.env.REACT_APP_API_URL;

function removeMarkdown(markdown) {
  return (
    markdown
      // Remove code blocks
      .replace(/```[\s\S]*?```/g, "")
      // Remove inline code
      .replace(/`[^`]*`/g, "")
      // Remove images
      .replace(/!\[.*?\]\(.*?\)/g, "")
      // Remove links
      .replace(/\[([^\]]+)\]\((.*?)\)/g, "$1")
      // Remove blockquotes
      .replace(/^>\s+/gm, "")
      // Remove headers
      .replace(/^#{1,6}\s+(.*)$/gm, "$1")
      // Remove horizontal rules
      .replace(/^(-{3,}|_{3,}|\*{3,})$/gm, "")
      // Remove bold and italic
      .replace(/(\*\*|__)(.*?)\1/g, "$2")
      .replace(/(\*|_)(.*?)\1/g, "$2")
      // Remove strikethrough
      .replace(/~~(.*?)~~/g, "$1")
      // Remove lists
      .replace(/^\s*([-+*]|(\d+\.))\s+/gm, "")
  );
}

export const downloadPDF = async (exam, index) => {
  if (localStorage.getItem("token")) {
    console.log("Downloading PDF", exam, index);

    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const pageMargin = 20; // Margen superior e inferior de cada página

    // Configuración inicial del documento
    doc.setFont("helvetica", "normal");

    // URL del logo
    const logoUrl = "/logo512.png";

    // Cargar y agregar el logo en el centro
    doc.addImage(logoUrl, "PNG", 85, 10, 40, 40, undefined, "FAST");

    let yPosition = 60;

    // Encabezado debajo del logo
    doc.setFontSize(14);
    doc.setTextColor(40);
    doc.text(
      "Generado por fotoexamen.com",
      105,
      yPosition,
      null,
      null,
      "center"
    );
    yPosition += 10;

    doc.setDrawColor(0);
    doc.setLineWidth(0.2);
    doc.line(10, yPosition, 200, yPosition);

    yPosition += 10;

    // Añadir el enunciado
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Enunciado:", 10, yPosition);
    yPosition += 10;

    doc.setFont("helvetica", "normal");
    const enunciadoLines = doc.splitTextToSize(exam.ocrText, 180);
    doc.text(enunciadoLines, 10, yPosition);
    yPosition += enunciadoLines.length * 7;

    // Comprobar si hay espacio suficiente para comenzar el resultado en la misma página
    if (yPosition + 40 > pageHeight - pageMargin) {
      doc.addPage();
      yPosition = pageMargin;
    } else {
      yPosition += 20; // Espacio adicional entre enunciado y resultado
    }

    // Añadir el texto del resultado
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Resultado:", 10, yPosition);
    yPosition += 10;

    // Procesar el resultado
    doc.setFont("helvetica", "normal");
    const resultText = exam.response;
    const mathRegex = /\$\$([\s\S]*?)\$\$|\$(.*?)\$/g;
    let match;
    let lastIndex = 0;

    let equationIndex = 0;
    const equationElements = document.getElementsByClassName("katex-html");

    console.log("Starting to process result text");
    console.log("Result text:", resultText);
    console.log("Number of equation elements:", equationElements.length);

    while ((match = mathRegex.exec(resultText)) !== null) {
      console.log("Found math expression:", match[0]);
      // Añadir el texto antes de la fórmula
      const beforeMath = removeMarkdown(
        resultText.slice(lastIndex, match.index)
      );
      const beforeMathLines = doc.splitTextToSize(beforeMath, 180);
      doc.text(beforeMathLines, 10, yPosition);
      yPosition += beforeMathLines.length * 7;

      console.log("Added text before math. New Y position:", yPosition);

      // Obtener la imagen de la ecuación ya renderizada
      if (equationElements[equationIndex]) {
        console.log("Rendering equation to canvas");
        const canvas = await html2canvasPro(equationElements[equationIndex], {
          scale: 2,
          useCORS: true,
          logging: true,
        });

        const imgData = canvas.toDataURL("image/png");
        const maxImgWidth = 180;
        const maxImgHeight = 100; // Ajusta este valor según sea necesario
        let imgWidth = maxImgWidth;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Si la altura es mayor que maxImgHeight, ajustamos el ancho proporcionalmente
        if (imgHeight > maxImgHeight) {
          imgHeight = maxImgHeight;
          imgWidth = (canvas.width * imgHeight) / canvas.height;
        }

        console.log("Image dimensions:", imgWidth, "x", imgHeight);

        if (yPosition + imgHeight > pageHeight - pageMargin) {
          console.log("Adding new page");
          doc.addPage();
          yPosition = pageMargin;
        }

        // Centrar las ecuaciones de bloque ($$) y alinear a la izquierda las ecuaciones en línea ($)
        const xPosition = match[0].startsWith("$$")
          ? (doc.internal.pageSize.width - imgWidth) / 2
          : 10;

        doc.addImage(
          imgData,
          "PNG",
          xPosition,
          yPosition,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        yPosition += imgHeight + (match[0].startsWith("$$") ? 10 : 5); // Más espacio para ecuaciones de bloque
        console.log("Added equation image. New Y position:", yPosition);
      }

      lastIndex = match.index + match[0].length;
      equationIndex++;
    }

    console.log("Finished processing result text");

    // Añadir el texto restante después de la última fórmula
    const remainingText = removeMarkdown(resultText.slice(lastIndex));
    const remainingLines = doc.splitTextToSize(remainingText, 180);

    if (yPosition + remainingLines.length * 7 > pageHeight - pageMargin) {
      doc.addPage();
      yPosition = pageMargin;
    }

    doc.text(remainingLines, 10, yPosition);

    // Guardar el PDF
    const examDate = new Date(exam?.lastOCR).toISOString().slice(0, 10);
    if (index) {
      doc.save(`Examen-${examDate}-ID${index + 1}.pdf`);
    } else {
      doc.save(`Examen-${examDate}.pdf`);
    }
  } else {
    console.log("Downloading PDF mobile: ", exam, index);

    const styles = StyleSheet.create({
      page: {
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: 10,
      },
      logo: { width: 40, height: 40, margin: "20px auto" },
      header: { textAlign: "center", margin: 10 },
      line: {
        marginTop: 5,
        marginBottom: 10,
        borderWidth: 0.5,
        borderColor: "#000",
      },
      text: { margin: 10, fontSize: 12 },
      title: { fontSize: 14, fontWeight: "bold", margin: 10, color: "#404040" },
    });

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <Image
            src="https://app.fotoexamen.com/logo512.png"
            style={styles.logo}
          />
          <Text style={styles.header}>Generado por fotoexamen.com</Text>
          <View style={styles.line} />
          <Text style={styles.title}>Enunciado:</Text>
          <Text style={styles.text}>{exam.ocrText}</Text>
        </Page>
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>Resultado:</Text>
          <Text style={styles.text}>{removeMarkdown(exam.response)}</Text>
        </Page>
      </Document>
    );

    try {
      const blob = await pdf(<MyDocument />).toBlob();
      try {
        saveAs(
          blob,
          `Examen-${new Date(exam.lastOCR).toISOString().slice(0, 10)}-ID.pdf`
        );
      } catch (error) {
        console.error("Error saving file in chrome:", error);
      }
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const base64data = reader.result;
        const path = `Download/Examen-${new Date(exam.lastOCR)
          .toISOString()
          .slice(0, 10)}.pdf`;
        let file = await Filesystem.writeFile({
          path: path,
          data: base64data,
          directory: Directory.ExternalStorage,
        });
        console.log("File saved successfully");
        try {
          await FileOpener.openFile({
            path: file.uri,
            mimeType: "application/pdf",
          });
        } catch (openError) {
          console.error("Error opening file:", openError);
        }
      };
    } catch (error) {
      console.error("Error generating or saving PDF:", error);
    }
  }
};

export const formatText = (text) => {
  if (typeof text !== "string") {
    console.error("formatText was called with a non-string argument", text);
    return text; // Return an empty string or handle the error as appropriate
  }
  return text.replace(/\\r\\n|\\r|\\n/g, "\n");
};

const formatTextWithNewlines = (text) => {
  return text.split("\n").map((item, index) => (
    <React.Fragment key={index}>
      {item}
      <br />
    </React.Fragment>
  ));
};

const ExamList = ({ setShowNoTokenModal }) => {
  const [exams, setExams] = useState([]);
  const [currentExams, setCurrentExams] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const examsPerPage = 10;
  const [isDownloading, setIsDownloading] = useState(false);

  const fetchExams = async () => {
    setLoading(true);
    setError(null);
    let token = localStorage.getItem("token");
    if (!token) {
      const ret = await Preferences.get({ key: "token" });
      if (ret?.value) {
        token = ret?.value;
      }
    }

    try {
      if (!token) {
        console.error("No authentication token found.");
        setShowNoTokenModal(true);
        return;
      }
      const response = await fetch(`${apiUrl}/api/easyexams/exams`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok.");
      const data = await response.json();
      setExams(data.sort((a, b) => new Date(b.lastOCR) - new Date(a.lastOCR)));
      setCurrentPage(1);
    } catch (error) {
      setError("No hay exámenes en el historial.");
      setExams([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExams();
  }, []);

  useEffect(() => {
    const indexOfLastExam = currentPage * examsPerPage;
    const indexOfFirstExam = indexOfLastExam - examsPerPage;
    setCurrentExams(exams.slice(indexOfFirstExam, indexOfLastExam));
  }, [currentPage, exams]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      const targetDate = date.toLocaleDateString("en-CA"); // 'en-CA' uses the format yyyy-mm-dd
      const filteredExams = exams.filter((exam) => {
        const examDate = new Date(exam.lastOCR).toLocaleDateString("en-CA");
        return examDate === targetDate;
      });
      setExams(filteredExams);
      setCurrentPage(1);
    } else {
      fetchExams(); // Reload all exams if date is cleared
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(exams.length / examsPerPage);

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <CurrentPath text={"Historial de exámenes"} />
      <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
        🖇️ Historial de exámenes resueltos
      </h1>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="yyyy/MM/dd"
        isClearable
        placeholderText="Seleccionar una fecha"
        className="p-2 w-full rounded bg-white text-black custom-datepicker"
      />
      {loading && <p className="mb-4">Cargando...</p>}
      {error && <p className="mb-4 text-black">{error}</p>}
      {!loading &&
        !error &&
        currentExams.map((exam, index) => (
          <div
            key={index}
            className="collapse collapse-plus bg-base-200 rounded-box shadow my-2"
          >
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              Examen "
              {exam.ocrText.split(" ").slice(0, 2).join(" ").toLowerCase()}" -{" "}
              {new Date(exam.lastOCR).toLocaleDateString("es-ES")} -{" "}
              {new Date(exam.lastOCR).toLocaleTimeString("es-ES")}
            </div>
            <div className="collapse-content bg-base-100 text-black p-4">
              <div className="font-semibold">Enunciado:</div>
              <br />
              <p>{exam.ocrText}</p>
              {/* separator */}
              <hr className="my-4" />
              <div className="font-semibold mt-2">Resultado:</div>
              <br />
              <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
                <MarkdownPreview content={exam?.response} />
              </div>
              {isDownloading ? (
                <div className="flex flex-col justify-center items-center mb-2 mt-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-red-600"></div>
                  <p className="text-sm font-semibold mt-2">Descargando PDF...</p>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setIsDownloading(true);
                    downloadPDF(exam, exam?.image ? exam?.image : "").finally(() => setIsDownloading(false));
                  }}
                  className="mb-2 bg-red-200 hover:bg-red-300 text-red-800 font-semibold py-2 px-4 rounded transition ease-in-out duration-300 flex items-center justify-center mt-4"
                >
                  <FaFilePdf size={24} className="mr-2" /> Descargar PDF 
                </button>
              )}
            </div>
          </div>
        ))}
      <div className="mt-4 flex justify-between">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-primary"
        >
          Anterior
        </button>
        <p className="text-lg">
          Página {currentPage} de {totalPages}
        </p>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn btn-primary"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};
export default ExamList;
